window.onscroll = () => {
  if (window.scrollY !== 0) {
    document.querySelector("header").classList.add("header--scrolled");
  } else {
    document.querySelector("header").classList.remove("header--scrolled");
  }
};

window.onload = () => {
  // Copy the partners to the end of the container for seamless loop back
  const partnersContainer = document.querySelector("#slide-track");
  NodeList.prototype.forEach = Array.prototype.forEach;
  let children = partnersContainer.childNodes;
  children.forEach(function (item) {
    let cln = item.cloneNode(true);
    partnersContainer.appendChild(cln);
  });
};

const addSubscriber = async () => {
  const email = document.querySelector("#subscribe_input").value;
  if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
    document
      .querySelector("#subscribe_input")
      .classList.add("subscribe-input-hidden");
    document
      .querySelector("#subscribe_button")
      .classList.add("subscribe-button-success");
    document.querySelector("#subscribe_button").innerHTML =
      "Invalid Email! Please try again";
    setTimeout(() => {
      document
        .querySelector("#subscribe_input")
        .classList.remove("subscribe-input-hidden");
      document
        .querySelector("#subscribe_button")
        .classList.remove("subscribe-button-success");
      document.querySelector("#subscribe_button").innerHTML = "Submit";
    }, 3000);
    return;
  }
  await fetch(`${process.env.SERVER_URL}/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(JSON.stringify(response));
      document
        .querySelector("#subscribe_input")
        .classList.add("subscribe-input-hidden");
      document
        .querySelector("#subscribe_button")
        .classList.add("subscribe-button-success");
      document.querySelector("#subscribe_button").innerHTML =
        "Thank you! You're all set.";
      setTimeout(() => {
        document
          .querySelector("#subscribe_input")
          .classList.remove("subscribe-input-hidden");
        document
          .querySelector("#subscribe_button")
          .classList.remove("subscribe-button-success");
        document.querySelector("#subscribe_button").innerHTML = "Submit";
      }, 3000);
    })
    .catch((error) => {
      console.log("error subscribing", error);
      document
        .querySelector("#subscribe_input")
        .classList.add("subscribe-input-hidden");
      document
        .querySelector("#subscribe_button")
        .classList.add("subscribe-button-success");
      document.querySelector("#subscribe_button").innerHTML =
        "Sorry! We got some error.";
      setTimeout(() => {
        document
          .querySelector("#subscribe_input")
          .classList.remove("subscribe-input-hidden");
        document
          .querySelector("#subscribe_button")
          .classList.remove("subscribe-button-success");
        document.querySelector("#subscribe_button").innerHTML = "Submit";
      }, 3000);
    });
};

document
  .querySelector("#subscribe_button")
  .addEventListener("click", async () => {
    await addSubscriber();
  });

const toggleHamburger = () => {
  const state = document.querySelector("#hamburger").dataset.active;
  if (state === "false") {
    document.querySelector("#hamburger").dataset.active = "true";
    document.querySelector("#hamburger").style.display = "block";
  } else {
    document.querySelector("#hamburger").dataset.active = "false";
    document.querySelector("#hamburger").style.display = "none";
  }
};

document.querySelector("#hamburger-btn").addEventListener("click", () => {
  toggleHamburger();
});

const days = document.querySelector("#countdown-days");
const hours = document.querySelector("#countdown-hrs");
const minutes = document.querySelector("#countdown-mins");
const seconds = document.querySelector("#countdown-secs");

const currentYear = new Date().getFullYear();
const newYear = new Date("2/28/2023 23:59:59 PST");

console.log(newYear);

function updateTime() {
  const currentDate = new Date();
  const diff = newYear - currentDate;
  const d = Math.floor(diff / 1000 / 60 / 60 / 24);
  const h = Math.floor((diff / 1000 / 60 / 60) % 24);
  const m = Math.floor((diff / 1000 / 60) % 60);
  const s = Math.floor((diff / 1000) % 60);
  const ms = Math.floor(diff % 1000);

  days.innerText = d < 10 ? (d > 0 ? "0" + d : "00") : d;
  hours.innerText = h < 10 ? (h > 0 ? "0" + h : "00") : h;
  minutes.innerText = m < 10 ? (m > 0 ? "0" + m : "00") : m;
  seconds.innerText = s < 10 ? (s > 0 ? "0" + s : "00") : s;
}

updateTime();
setInterval(updateTime, 1000);

// Toastify({
//   text: "Hackathon deadline extended for 48 hours!",
//   className: "toast",
//   duration: -1,
//   destination: "https://flow-hackathon.devfolio.co/",
//   newWindow: true,
//   close: true,
//   gravity: "bottom",
//   position: "right",
// }).showToast();
